import React from 'react';
import styled from 'styled-components';
// import { Row, Col } from 'antd';

// import AyalalandLogo from '../../../static/images/services/developers/ayalaland.jpg';
// import SMPrimeLogo from '../../../static/images/services/developers/sm-prime.jpg';
// import MegaworldLogo from '../../../static/images/services/developers/megaworld.jpg';
// import RobinsonsLandLogo from '../../../static/images/services/developers/robinsons-land.jpg';
// // import TopliteLogo from '../../../static/images/services/developers/toplite.jpg';
// // import BenLorLogo from '../../../static/images/services/developers/ben-lor.jpg';
// // import FelcrisLogo from '../../../static/images/services/developers/felcris-centrale.jpg';
// // import PMILogo from '../../../static/images/services/developers/pmi.jpg';
// import EtonLogo from '../../../static/images/services/developers/eton.jpg';
// import DDLogo from '../../../static/images/services/developers/dd.jpg';
// import KeppelLogo from '../../../static/images/services/developers/keppel-land.jpg';
// import GreenfieldLogo from '../../../static/images/services/developers/greenfield.jpg';

import DLLMainSubset from './subset/DLLMainSubset';

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  h2 {
    margin-bottom: 24px;
    span {
      font-family: Poppins, sans-serif;
      color: ${props => `${props.theme.primeBluePalette.original}`};
    }
  }
  .developer-logo {
    margin-bottom: 0px;
  }
  .first-row {
    /* make sure this is also equal to gutter value at row */
    margin-bottom: 40px;
  }
`;
function DeveloperLandlordLogos() {
  return (
    <Wrapper>
      <h2>
        Trusted by <span>Developers</span>
      </h2>
      <DLLMainSubset />
      {/* <Row className="first-row" type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img className="developer-logo" alt="Ayalaland" src={AyalalandLogo} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img className="developer-logo" alt="SM Prime" src={SMPrimeLogo} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img className="developer-logo" alt="Megaworld" src={MegaworldLogo} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img
            className="developer-logo"
            alt="Robinsons Land"
            src={RobinsonsLandLogo}
          />
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img
            className="developer-logo"
            alt="ETON Properties Philippines, Inc"
            src={EtonLogo}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img
            className="developer-logo"
            alt="Double Dragon Philippines Corp"
            src={DDLogo}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img
            className="developer-logo"
            alt="Keppel Land Realty Corp"
            src={KeppelLogo}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <img
            className="developer-logo"
            alt="Greenfield Development Corp"
            src={GreenfieldLogo}
          />
        </Col>
      </Row> */}
    </Wrapper>
  );
}

export default DeveloperLandlordLogos;
