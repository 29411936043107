import React, { useState, useRef } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { Input, Radio, Divider, Button, Select, notification } from 'antd';
import styled, { keyframes } from 'styled-components';
import { isValidEmail, isNull } from '../../utilities/formatChecker';

const { TextArea } = Input;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledDiv = styled.div`
  border-radius: 3px;
  background-color: white;
  padding: 30px;
  box-shadow: 0px 4px 9px 3px rgba(0, 0, 0, 0.2);

  visibility: ${props => (props.out ? 'hidden' : 'visible')};
  animation: ${props => (props.out ? fadeOut : fadeIn)} 1s linear;
  transition: visibility 1s linear;

  .ant-radio-group {
    width: 100%;
  }

  .inquiry-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      flex: 1;
    }
  }

  .inquiry-row.text {
    font-size: 20px;
    font-weight: 700;
    color: ${props => props.theme.blackPalette.original};
  }

  .inquiry-row.subtext {
    font-size: 0.8rem;
    color: ${props => props.theme.bluePalette.light};
  }

  .button-row {
    margin-top: 24px;
  }

  .contact-row.title {
    margin-bottom: 16px;
  }

  .input-field-wrapper {
    margin-bottom: 16px;
  }

  .input-field {
    background-color: #f2faff;
  }
  .input-field.select {
    width: 100%;
  }

  .input-label {
    margin-bottom: 6px;
  }

  .form-action-button {
    display: flex;
    button {
      flex: 1;
      margin-right: 10px;
    }
    button:last-child {
      flex: 1;
      margin-right: 0px;
    }
  }
`;

function ContactFormComponent() {
  const recaptchaRef = useRef(null);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isEmailProvided, setIsEmailProvided] = useState(false);
  const [selectedService, setSelectedService] = useState(1);
  const [formData, setFormData] = useState({
    companyIdentifier: 'PRIMEPH',
    email: '',
    fullname: '',
    contactNumber: '',
    message: '',
    sizeRequirement: '',
    propertyType: null,
    recaptcha: '',
  });
  const [errorFields, setErrorFields] = useState({
    email: '',
    fullname: '',
    contactNumber: '',
    message: '',
    sizeRequirement: '',
    propertyType: '',
    recaptcha: '',
  });

  function changeDisplayedForm() {
    if (formData.email) {
      const errorReason = !isValidEmail(formData.email)
        ? 'Please input your valid email address'
        : '';
      setIsEmailProvided(isNull(errorReason));
      setFormData({ ...formData, recaptcha: '' });
      setErrorFields({ ...errorFields, email: errorReason, recaptcha: '' });
    } else {
      setErrorFields({
        ...errorFields,
        email: 'Please input your email address',
      });
    }
  }

  function isFormDataComplete() {
    const sizeRequirement =
      selectedService <= 2
        ? isNull(formData.sizeRequirement.trim())
          ? 'Indicate your size requirement/property size'
          : ''
        : '';
    const propertyType =
      selectedService <= 2
        ? isNull(formData.propertyType.trim())
          ? 'Indicate your property type requirement/property type'
          : ''
        : '';

    const formErrors = {
      sizeRequirement,
      propertyType,
      fullname: isNull(formData.fullname.trim())
        ? 'Please provide your complete name'
        : '',
      contactNumber: isNull(formData.contactNumber.trim())
        ? 'Please provide your contact number'
        : '',
      email: isNull(formData.email.trim())
        ? 'Please provide your email address'
        : '',
      message: isNull(formData.message.trim())
        ? 'Please give us more information on your inquiry'
        : '',
      recaptcha: isNull(formData.recaptcha.trim())
        ? 'Please validate recaptcha to proceed'
        : '',
    };

    setErrorFields(formErrors);
    return Object.values(formErrors).filter(err => !isNull(err)).length === 0;
  }

  function submitFormData() {
    if (isFormDataComplete()) {
      setIsSendingEmail(true);
      const dataToSubmit = {
        company_identifier: formData.companyIdentifier,
        email_address: formData.email,
        data: [
          {
            key: 'Selected Option',
            value: [
              'I am looking for spaces',
              'I need a property consultation',
              'I am looking for a job',
            ][selectedService - 1],
          },
          { key: 'Name', value: formData.fullname },
          { key: 'Email Address', value: formData.email },
          { key: 'Contact Number', value: formData.contactNumber },
          { key: 'Message', value: formData.message },
          {
            key: 'Size Requirement',
            value: formData.sizeRequirement,
          },
          {
            key: 'Property Type',
            value: formData.propertyType,
          },
        ],
      };
      fetch(`${process.env.STRAPI_API}/email-requests`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      })
        .then(response => response.json())
        .then(() => {
          setFormData({
            companyIdentifier: 'PRIMEPH',
            email: '',
            fullname: '',
            contactNumber: '',
            message: '',
            sizeRequirement: '',
            propertyType: null,
            recaptcha: '',
          });

          setErrorFields({
            email: '',
            fullname: '',
            contactNumber: '',
            message: '',
            sizeRequirement: '',
            propertyType: '',
          });
          notification.success({
            message: 'Inquiry Sent',
            description:
              'Thank you for inquiring with us. Our team will get back to you as soon as we get it.',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Something Went Wrong!',
            description:
              'There was probleming sending your inquiry. Please try again later.',
          });
        })
        .finally(() => {
          setIsSendingEmail(false);
        });
    } else
      notification.warning({
        message: 'Incomplete Fields',
        description: 'Please complete all required fields.',
      });
  }

  return isEmailProvided ? (
    <StyledDiv>
      <div className="contact-row title">
        We need more information about you to address your questions:
      </div>
      <div
        className={`input-field-wrapper ${
          errorFields.fullname ? 'has-error' : ''
        }`}
      >
        <Input
          className="input-field"
          type="text"
          onChange={e => setFormData({ ...formData, fullname: e.target.value })}
          value={formData.fullname}
          placeholder="Complete name"
        />
        {errorFields.fullname ? (
          <div className="error-explain">{errorFields.fullname}</div>
        ) : null}
      </div>

      <div
        className={`input-field-wrapper ${
          errorFields.contactNumber ? 'has-error' : ''
        }`}
      >
        <Input
          className="input-field"
          type="text"
          onChange={e =>
            setFormData({ ...formData, contactNumber: e.target.value })
          }
          value={formData.contactNumber}
          placeholder="Contact Number"
        />
        {errorFields.contactNumber ? (
          <div className="error-explain">{errorFields.contactNumber}</div>
        ) : null}
      </div>

      {selectedService !== 3 ? (
        <span>
          <div
            className={`input-field-wrapper ${
              errorFields.propertyType ? 'has-error' : ''
            }`}
          >
            <Select
              className="input-field select"
              type="text"
              onChange={value =>
                setFormData({ ...formData, propertyType: value })
              }
              value={formData.propertyType}
              placeholder="Property Type"
              options={[
                { value: 'Office space', label: 'Office space' },
                { value: 'Commercial space', label: 'Commercial space' },
                { value: 'Commercial lot', label: 'Commercial lot' },
                {
                  value: 'Industrial lot/warehouse',
                  label: 'Industrial lot/warehouse',
                },
                { value: 'Other', label: 'Other' },
              ]}
            />
            {errorFields.propertyType ? (
              <div className="error-explain">{errorFields.propertyType}</div>
            ) : null}
          </div>
          <div
            className={`input-field-wrapper ${
              errorFields.sizeRequirement ? 'has-error' : ''
            }`}
          >
            <Input
              className="input-field"
              type="text"
              onChange={e =>
                setFormData({ ...formData, sizeRequirement: e.target.value })
              }
              value={formData.sizeRequirement}
              placeholder="Size Requirement"
            />
            {errorFields.sizeRequirement ? (
              <div className="error-explain">{errorFields.sizeRequirement}</div>
            ) : null}
          </div>
        </span>
      ) : null}

      <div
        className={`input-field-wrapper ${
          errorFields.message ? 'has-error' : ''
        }`}
      >
        <TextArea
          className="input-field"
          placeholder="Message"
          onChange={e => setFormData({ ...formData, message: e.target.value })}
          value={formData.message}
          rows={3}
        />

        {errorFields.message ? (
          <div className="error-explain">{errorFields.message}</div>
        ) : null}
      </div>

      <div
        className={`google-recaptcha input-field-wrapper ${
          errorFields.recaptcha ? 'has-error' : ''
        }`}
      >
        <Recaptcha
          ref={recaptchaRef}
          sitekey="6LdehiUTAAAAALj4VhxGS0EEMsC6aech1yN8UFfk"
          onChange={value => {
            setFormData({ ...formData, recaptcha: value });
            setErrorFields({ ...errorFields, recaptcha: '' });
          }}
        />
        {errorFields.recaptcha ? (
          <div className="error-explain">{errorFields.recaptcha}</div>
        ) : null}
      </div>

      <div className="form-action-button">
        <Button type="Link" onClick={() => setIsEmailProvided(false)}>
          Back
        </Button>

        <Button
          type="primary"
          onClick={submitFormData}
          loading={isSendingEmail}
        >
          Submit
        </Button>
      </div>
    </StyledDiv>
  ) : (
    <StyledDiv>
      <div
        className={`input-field-wrapper ${
          errorFields.email ? 'has-error' : ''
        }`}
      >
        <div className="input-label required-input-label">
          Enter your email address
        </div>
        <Input
          className="input-field"
          type="email"
          onChange={e => setFormData({ ...formData, email: e.target.value })}
          value={formData.email}
          placeholder="Email Address"
          onPressEnter={changeDisplayedForm}
        />
        {errorFields.email ? (
          <div className="error-explain">{errorFields.email}</div>
        ) : null}
      </div>

      <div className="contact-row title">
        What service do you need for help?
      </div>

      <Radio.Group
        onChange={e => setSelectedService(e.target.value)}
        value={selectedService}
      >
        <div className="inquiry-row">
          <div>
            <div className="inquiry-row text">I am looking for spaces</div>
            <div className="inquiry-row subtext">
              Inquire space from one of our leasing professionals
            </div>
          </div>
          <Radio value={1}></Radio>
        </div>
        <Divider />
        <div className="inquiry-row">
          <div>
            <div className="inquiry-row text">
              I need a property consultation
            </div>
            <div className="inquiry-row subtext">
              Ask for assistance from our real estate advisors
            </div>
          </div>
          <Radio value={2}></Radio>
        </div>
        <Divider />
        <div className="inquiry-row">
          <div>
            <div className="inquiry-row text">I am looking for a job</div>
            <div className="inquiry-row subtext">
              Join us and be part of our young and energetic team!
            </div>
          </div>
          <Radio value={3}></Radio>
        </div>
        <br />
        <div className="button-row">
          <Button
            className="button submit"
            type="primary"
            onClick={changeDisplayedForm}
            block
          >
            Confirm
          </Button>
        </div>
      </Radio.Group>
    </StyledDiv>
  );
}

export default ContactFormComponent;
