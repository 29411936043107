import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  h3 {
    margin-bottom: 24px;
    line-height: 1.15;
    color: ${props => props.theme.primeBluePalette.original};
  }
  span, p, li {
    font-size: 20px;
    line-height: 28px;
  }
  strong {
    display: block;
    font-size: 24px;
    line-height: 32px;
    /* color: ${props => props.theme.primeBluePalette.original}; */
    color: ${props => props.theme.primeBluePalette.original};
  }
  ol {
    margin-top: 24px;
    margin-bottom: 0 !important;
  }
  img {
    width: 100%;
    max-width: 400px;
  }
  @media only screen and (max-width: 776px) {
    li,
    span {
      font-size: 16px;
      line-height: 22px;
    }
    
    strong {
      font-size: 20px;
      line-height: 28px;
      /* color: ${props => props.theme.primeBluePalette.original}; */
    }
  }
`;

function CMICaseStudies() {
  return (
    <Wrapper>
      <h3>Case studies</h3>
      <h3>Commercial center in Quezon City</h3>
      <ul>
        <li>
          <strong>Background of the Case and Challenges</strong>A prime property
          first introduced in the market in 2013, it had experienced consecutive
          years of low occupancy and reflected a net loss for the company. For
          the next two years, the property was still in the market and continued
          to experience a high vacancy rate. Aside from this, multiple brokers
          were handling the property, without aggressive marketing in-place.
        </li>
        <li>
          <strong>Our Solution and The Result</strong>PRIME Philippines’ retail
          leasing team aggressively marketed the property and helped increase
          the building&apos;s occupancy rate. <br />
          <br /> Parallel to this, PRIME was awarded as the exclusive broker for
          the sale of the property to centralize the marketing efforts and
          information dissemination in promoting the property. The Capital
          Markets and Investment team, together with Research and Advisory
          prepared a well-crafted property pitch deck to effectively market the
          property. <br />
          <br /> Within four (4) months, PRIME Philippines was able to sell the
          property. The transaction was considered the largest single-ticket
          transaction closed in 2015.
        </li>
      </ul>
      <h3>Quezon Avenue corner lot</h3>
      <ul>
        <li>
          <strong>Background of the Case and Challenges</strong>The 3-storey
          commercial building was located along Quezon Avenue, was considered
          one of the oldest buildings in the area and was opened to the market
          in 2016. The property is underutilized given the current FAR, and the
          value of the land requires the investor to develop a high-rise
          building to recuperate and fully maximize his/her investment.
        </li>
        <li>
          <strong>Our Solution and The Result</strong>The investment team and
          the research and advisory team conducted a highest and best use study
          for the property. Based on the result of the study, the
          property&apos;s best and most profitable use is to develop an office
          building roughly 28 to 30-storeys high. An investor’s kit was prepared
          using the data from the study. <br />
          <br /> In mid-2016, PRIME Philippines was able to sell the property
          through aggressively marketing to its network of clients. The team
          also assisted in the transfer of the property documents to the new
          owner of the property. Furthermore, the new owner was provided the
          baseline data of the investor’s kit and in 2019, the 28-storey office
          development situated in the same property was finished.
        </li>
      </ul>
      <h3>Clark Global City</h3>
      <ul>
        <li>
          <strong>Background of the Case and Challenges</strong>A master-planned
          business district located in Clark Freeport Zone, Pampanga was
          launched in 2008. However, for the last decade, the project had
          challenges in attracting investors.
        </li>
        <li>
          <strong>Our Solution and The Result</strong>PRIME’s Capital Markets
          and Investments Team, in partnership with the Research and Advisory
          Team carefully studied and crafted a strategy on how we can help the
          developer in positioning the project and make it attractive to
          potential locators. Using the result of this study, our team created
          an investment pitch deck for investors’ perusal to guide them in their
          expansion plans and decision-making process.
          <br />
          <br />
          Within a year of marketing the project, our team was able to transact
          six (6) lots. Furthermore, we were able to help these groups of
          investors in easily locating inside Clark Freeport Zone through our
          Special Economic Zone Real Estate Documentation and Permitting
          Services.
        </li>
      </ul>
    </Wrapper>
  );
}

export default CMICaseStudies;
