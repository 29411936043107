import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactFormComponent from '../components/contact-us-page/ContactFormComponent';
import E2EGraphicRowComponent from '../components/services/E2EGraphicRowComponent';
import ResearchCaseStudies from '../components/services/ResearchCaseStudies';
import CMICaseStudies from '../components/services/CMICaseStudies';
import HLCaseStudies from '../components/services/HLCaseStudies';
import DeveloperLandlordProperties from '../components/services/DeveloperLandlordProperties';
import DeveloperLandlordLogos from '../components/services/DeveloperLandlordLogos';
// import DeveloperLandlordTestimonials from '../components/services/DeveloperLandlordTestimonials';
import TenantRepresentationLogos from '../components/services/TenantRepresentationLogos';

const StyledDivWrapper = styled.div`
  padding-top: 89px;
  overflow: hidden;
  h1 {
    margin-bottom: 0;
  }
  .service-wrapper {
    max-width: 1440px;
    margin: auto;
    margin-bottom: 40px;
  }

  .hero-wrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    ::after {
      content: '';
      top: 0%;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.35) 50%,
        rgba(0, 0, 0, 0.75) 100%
      );
    }

    h1 {
      text-align: center;
      z-index: 1;
      color: white;
      text-shadow: 0px 0px 5px rgba(33, 33, 33, 0.2);
    }
  }

  .service-tags {
    padding: 30px 0px;
    > div {
      background-color: white;
      display: inline-block;
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.3);

      div {
        display: inline-block;
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
      }

      > div:first-child {
        padding: 10px 30px;
        background-color: #003366;
        color: white;
        @media only screen and (max-width: 320px) {
          padding: 10px 20px 10px;
        }
      }
      > div:last-child {
        background-color: white;
        padding: 10px 30px 10px 10px;
        @media only screen and (max-width: 320px) {
          padding: 10px 10px 10px 5px;
        }
      }
    }
  }

  .content-area {
    padding-bottom: 30px;
    p {
      /* margin-top: 32px; */
      font-size: 1.15em;
      line-height: 1.15;
      font-weight: 600;
      font-family: 'Proxima Nova', sans-serif;
    }
    .service-title {
      padding: 0px 12px;
      margin-bottom: 24px;
      line-height: 1.15;
      color: ${props => props.theme.primeBluePalette.original};
    }
    .service-content {
      padding: 12px;
      span {
        font-size: 20px;
        line-height: 28px;
      }
      li,
      strong {
        font-size: 24px;
        line-height: 32px;
        /* color: ${props => props.theme.primeBluePalette.original}; */
      }
      strong {
        color: ${props => props.theme.primeBluePalette.original};
      }
      ol {
        margin-top: 24px;
        margin-bottom: 0 !important;
      }
      @media only screen and (max-width: 776px) {
        span {
          font-size: 16px;
          line-height: 22px;
        }
        li,
        strong {
          font-size: 20px;
          line-height: 28px;
          /* color: ${props => props.theme.primeBluePalette.original}; */
        }
      }
    }
    .service-details {
      font-size: 24px;
      line-height: 30px;
      margin-top: 16px;
    }
  }

  .person-card {
    min-width: 400px;

    @media only screen and (max-width: 500px) {
      min-width: 100%;
    }
    background-color: white;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;

    > div:last-child {
      padding: 20px;
    }
    .person-name {
      font-size: 1.5em;
      line-height: 1;
      font-weight: bold;
    }
    .person-position {
      color: #0080ff;
    }
  }

  .img-profile {
    max-width: 120px;
    margin-bottom: 0px;
  }

  .point-person-card {
    margin-bottom: 12px;
    width: 100%;
  }

  .contact-form {
    position: relative;
    max-width: 1440px;
    margin: auto;
    > .ant-row {
      position: relative;
      z-index: 1;
    }

    ::after {
      content: '';
      top: 0%;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      background-color: #f2f2f5;
      clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
      @media only screen and (max-width: 500px) {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
    }

    & .ant-row:first-child {
      padding: 1.5rem 0px;
      margin-bottom: 0px !important;
    }

    .icon-list-item {
      display: flex;
      align-items: center;
      > :first-child {
        font-size: 15px;
      }
      > :last-child {
        margin-left: calc(1rem * 0.3);
      }
    }

    .contact-content-wrap {
      h4,
      h5 {
        color: ${props => `${props.theme.blackPalette.original}`};
      }
    }

    @media only screen and (max-width: 576px) {
      clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 100%);
    }
  }
`;

// Here are some components that can be added to certain pages by using their string identifiers below.
// It works by having our custom function run through this set, subtituting any instances found
// of the string identifier with these components. The result is an array to be rendered.
const substituteComponents = {
  e2eGraphicRow: <E2EGraphicRowComponent key="e2e" />,
  // researchClientLogoRow: <div></div>,
  researchCaseStudies: <ResearchCaseStudies key="research" />,
  cmiCaseStudies: <CMICaseStudies key="cmi" />,
  hlCaseStudies: <HLCaseStudies key="hl" />,
  featuredProperties: <DeveloperLandlordProperties key="dlp" />,
  developerLogos: <DeveloperLandlordLogos key="dll" />,
  developerTestimonials: '',
  tenantRepresentationLogos: <TenantRepresentationLogos />,

  // developerTestimonials: <DeveloperLandlordTestimonials />,
};

// If you plan to re-implement the same system, consider moving this function to utils
// Note: For now, don't use these components in the middle of other markup we're rendering with dangerouslySetInnerHTML
const insertComponentsIntoText = (stringInput, replacements) => {
  const splitRegex = new RegExp(/\[\[(\w*)\]\]/g);
  const parts = stringInput.split(splitRegex);
  return parts.map(part => {
    if (Object.prototype.hasOwnProperty.call(replacements, part)) {
      return replacements[part];
    }
    // admittedly, this is dirty, please consider how to solve.
    // We're expecting just one div to come out with this in every page. If that doesn't happen,
    // that means you've inserted the strings in the middle of the content as opposed to just the ends.
    return (
      <div
        key="content"
        className="service-content"
        dangerouslySetInnerHTML={{
          __html: part,
        }}
      />
    );
  });
};

function ServicePageTemplate({ pageContext, location }) {
  const renderArray = insertComponentsIntoText(
    pageContext.serviceDetails.content,
    substituteComponents
  );
  return (
    <Layout>
      <SEO
        title={`${pageContext.serviceDetails.service_name}`}
        description={pageContext.serviceDetails.description}
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content: pageContext.serviceDetails.tags,
          },
        ]}
      />
      <StyledDivWrapper>
        <div className="service-wrapper">
          <div
            className="hero-wrapper"
            style={{
              backgroundImage: `url('${pageContext.serviceDetails.banner_photo}')`,
            }}
          >
            <h1>{pageContext.serviceDetails.service_name}</h1>
          </div>

          <div className="content-area">
            <Row type="flex" justify="center" className="form-wrapper">
              <Col xs={23} sm={23} md={22} lg={22} xl={22} xxl={20}>
                <div className="service-tags">
                  {/* <div>
                    <div>{pageContext.serviceDetails.service_type}</div>
                    <div>{pageContext.serviceDetails.category}</div>
                  </div> */}
                </div>

                <div>
                  <Row type="flex" justify="center" gutter={[40, 40]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={14}>
                      <div>
                        <h3 className="service-title">
                          {pageContext.serviceDetails.description}
                        </h3>
                      </div>
                      <div>
                        {/* first item in the render array assumed to be main, initial content */}
                        {renderArray[0]}
                        {/* <div
                          className="service-content"
                          dangerouslySetInnerHTML={{
                            __html: pageContext.serviceDetails.content,
                          }}
                        /> */}
                        {/* <p className="service-details">
                          {pageContext.serviceDetails.service_client_details}
                        </p> */}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
                      <div
                        style={{ display: 'inline-block' }}
                        className="point-person-card"
                      >
                        <div className="person-card">
                          <div>
                            <img
                              src={
                                pageContext.serviceDetails.point_person_photo
                              }
                              className="img-profile"
                              alt={pageContext.serviceDetails.point_person_name}
                            />
                          </div>
                          <div>
                            <div className="person-name">
                              {pageContext.serviceDetails.point_person_name}
                            </div>
                            <div className="person-position">
                              {pageContext.serviceDetails.point_person_position}
                            </div>
                          </div>
                        </div>
                      </div>
                      {pageContext.serviceDetails.point_person_name_2 !== '' ? (
                        <div
                          style={{ display: 'inline-block' }}
                          className="point-person-card"
                        >
                          <div className="person-card">
                            <div>
                              <img
                                src={
                                  pageContext.serviceDetails
                                    .point_person_photo_2
                                }
                                className="img-profile"
                                alt={
                                  pageContext.serviceDetails.point_person_name_2
                                }
                              />
                            </div>
                            <div>
                              <div className="person-name">
                                {pageContext.serviceDetails.point_person_name_2}
                              </div>
                              <div className="person-position">
                                {
                                  pageContext.serviceDetails
                                    .point_person_position_2
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {pageContext.serviceDetails.point_person_name_3 !== '' ? (
                        <div
                          style={{ display: 'inline-block' }}
                          className="point-person-card"
                        >
                          <div className="person-card">
                            <div>
                              <img
                                src={
                                  pageContext.serviceDetails
                                    .point_person_photo_3
                                }
                                className="img-profile"
                                alt={
                                  pageContext.serviceDetails.point_person_name_3
                                }
                              />
                            </div>
                            <div>
                              <div className="person-name">
                                {pageContext.serviceDetails.point_person_name_3}
                              </div>
                              <div className="person-position">
                                {
                                  pageContext.serviceDetails
                                    .point_person_position_3
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      {/* first item in the render array removed here */}
                      {renderArray.slice(1)}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="contact-form">
          <Row type="flex" justify="center">
            <Col xs={23} sm={22} md={23} lg={22} xl={22} xxl={22}>
              <Row gutter={[20, 20]} align="middle" justify="center">
                <Col
                  className="contact-content-wrap"
                  xs={{ span: 22 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                  xl={{ span: 8 }}
                  xxl={{ span: 6 }}
                >
                  <div>
                    <h4>How Can We Help?</h4>
                    <p className="contact-content">
                      Tell us your concerns and one of our young and energetic
                      team of professionals will get in touch with you. We look
                      forward to hearing from you.
                    </p>
                  </div>

                  <Divider />

                  <div>
                    <h5>Inquiries</h5>
                    <div className="icon-list-item">
                      <div>
                        <FontAwesomeIcon icon="envelope" fixedWidth />
                      </div>
                      <div>inqury@primephilippines.com</div>
                    </div>
                    <div className="icon-list-item">
                      <div>
                        <FontAwesomeIcon icon="phone-alt" fixedWidth />
                      </div>
                      <div>(+6328) 442 8888</div>
                    </div>
                  </div>

                  <br />

                  <div>
                    <h5>Careers</h5>
                    <div className="icon-list-item">
                      <div>
                        <FontAwesomeIcon icon="envelope" fixedWidth />
                      </div>
                      <div>careers@primephilippines.com</div>
                    </div>
                    <div className="icon-list-item">
                      <div>
                        <FontAwesomeIcon icon="phone-alt" fixedWidth />
                      </div>
                      <div>(+6327) 616 8554</div>
                    </div>
                  </div>
                </Col>

                <Col
                  className="contact-content-wrap"
                  xs={{ span: 22 }}
                  md={{ span: 12 }}
                  lg={{ span: 10 }}
                  xl={{ span: 8 }}
                  xxl={{ span: 8 }}
                >
                  <div className="contact-content">
                    <ContactFormComponent />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </StyledDivWrapper>
    </Layout>
  );
}

ServicePageTemplate.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default ServicePageTemplate;
