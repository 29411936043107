import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import E2EGraphicLarge from '../../../static/images/services/e2e-infographic.jpg';
import E2EGraphicSmall from '../../../static/images/services/e2e-mobile.jpg';

const Wrapper = styled.div`
  width: 100%;
  .mobile-component {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    img {
      width: 100%;
      max-width: 400px;
      margin-bottom: 42px;
    }
    p {
      /* margin-top: 32px; */
      font-size: 1.15em !important;
      line-height: 1.5 !important;
      font-weight: 500 !important;
      font-family: 'Proxima Nova', sans-serif !important;
      margin-bottom: 16px;
    }
    b {
      color: ${props => props.theme.primeBluePalette.original};
    }
    .mobile-col {
      padding: 0px 20px;
    }
  }

  @media only screen and (max-width: 950px) {
    .desktop-component {
      display: none;
    }
    .mobile-component {
      display: flex;
    }
  }
`;

function E2EGraphicRowComponent() {
  return (
    <Wrapper>
      <div className="desktop-component">
        <img
          alt="Our E2E services cover every part of the real estate lifecyle."
          src={E2EGraphicLarge}
        />
      </div>
      <div className="mobile-component">
        <img
          alt="Our E2E services cover every part of the real estate lifecyle."
          src={E2EGraphicSmall}
        />
        <Row type="flex">
          <Col
            className="mobile-col"
            xs={24}
            sm={24}
            md={12}
            lg={24}
            xl={24}
            xxl={24}
          >
            <p>
              <b>We Research</b>
              <br /> Our <b>Research and Advisory</b> team provides and analyzes
              the information necessary for decision-making and pre-development
              planning. Backed by over 100 real estate research studies from
              various property sectors nationwide, our researchers have the
              strong capabilities to provide reliable and dependable reports
              recognized by banks, financial institutions, publicly-listed
              companies, and global investors.
            </p>
            <p>
              <b>We Design</b>
              <br /> Our <b>Design Development</b> group has the expertise in
              reviewing and auditing architectural drawings to ensure each
              project’s profitability and operational efficiency. With over 20
              years of combined expertise, our professionals are equipped with
              the vast knowledge and best-case practices from top developers and
              firms.
            </p>
            <p>
              <b>We Build</b>
              <br /> Comprised of highly-experienced licensed engineers, our{' '}
              <b>Quantity Surveying and Project Management</b> team hails from
              various backgrounds including infrastructure, high-rise buildings,
              transportation, and other developments. This team ensures that all
              the necessary checks and balances, along with other safety and
              budgetary measures, are kept to the highest standard.
            </p>
          </Col>
          <Col
            className="mobile-col"
            xs={24}
            sm={24}
            md={12}
            lg={24}
            xl={24}
            xxl={24}
          >
            <p>
              <b>We Market</b>
              <br /> Unlike traditional structures, our <b>Brokerage</b>{' '}
              division is grouped into specialized areas of expertise, namely:
              Retail, Office, Industrial, and Investment groups. This
              segmentation allows our team to efficiently employ their skills,
              experiences, and deeper understandings of various target markets.
            </p>
            <p>
              <b>We Manage</b>
              <br /> Asset value preservation is the core expertise of our{' '}
              <b>Asset Management</b> team, banking on their vast experience of
              handling daily operations while also managing critical issues from
              various property developments across all sectors. Our team of
              professionals advises on the best value preservation or growth
              strategies, as well as assuring proper maintenance and upkeep, for
              your properties.
            </p>
            <p>
              <b>We Flip</b>
              <br /> By the time you are ready to flip your asset, our{' '}
              <b>Investment</b> unit is highly capable and experienced to handle
              big-ticket transactions on income generating properties. Equipped
              with our wide network database of family offices, private and
              public investors, and developers, our team can handle all your
              property disposal needs.
            </p>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

export default E2EGraphicRowComponent;
