import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { sanitizePropertyName, slugify } from '../../utilities/filter-listings';

import ProjectPropertiesComponent from '../home-page/ProjectPropertiesComponent';

const Wrapper = styled.div`
  width: 100%;
`;

function DeveloperLandlordProperties() {
  const data = useStaticQuery(graphql`
    {
      allPropertiesOfficeProjects(
        filter: {
          project_id: { in: [454, 111, 431, 670, 687, 737, 747, 177, 376, 178] }
        }
      ) {
        nodes {
          project_name
          property_type
          authority_type
          address
          city
          available_units
          building_grade
          designation
          date_completion
          handover_condition
          latitude
          longitude
          lot_area
          no_escalator
          no_of_basement
          no_of_storey
          no_passenger_elevator
          operating_hours
          project_id
          project_code
          tenant_preferences
          tenant_restrictions
          created_at {
            date(formatString: "MMM DD, YYYY")
          }
          project_image {
            data {
              image_path
              thumb_path
              image_id
            }
          }
        }
      }
      allPropertiesRetailProjects(
        filter: { project_id: { in: [430, 267, 476, 67, 40, 594] } }
      ) {
        nodes {
          project_name
          property_type
          authority_type
          address
          city
          available_units
          building_grade
          designation
          date_completion
          handover_condition
          latitude
          longitude
          lot_area
          no_escalator
          no_of_basement
          no_of_storey
          no_passenger_elevator
          operating_hours
          project_id
          project_code
          tenant_preferences
          tenant_restrictions
          created_at {
            date(formatString: "MMM DD, YYYY")
          }
          project_image {
            data {
              image_path
              thumb_path
              image_id
            }
          }
        }
      }
    }
  `);

  const properties = {
    manila: [],
    visMin: [],
    mindanao: [],
  };
  [
    ...data.allPropertiesOfficeProjects.nodes,
    ...data.allPropertiesRetailProjects.nodes,
  ]
    .map(node => ({
      status: '',
      strapiId: node.project_id,
      banner:
        node.project_image.data !== undefined ||
        node.project_image.data !== null
          ? node.project_image.data.length - 1 > 0
            ? `${process.env.IMAGES_URL_PATH}/${node.project_image.data[0].thumb_path}`
            : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
          : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg',
      thumb:
        node.project_image.data !== undefined ||
        node.project_image.data !== null
          ? node.project_image.data.length - 1 > 0
            ? `${process.env.IMAGES_URL_PATH}/${node.project_image.data[0].thumb_path}`
            : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
          : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg',
      url_link: `${slugify(sanitizePropertyName(node.project_name))}-${
        node.project_id
      }`,
      visibility: 1,
      propertyname: sanitizePropertyName(node.project_name),
      category: node.property_type,
      classification: 'For Lease',
      location: `${node.location || node.address}${
        node.city ? `, ${node.city}` : ''
      }`,
    }))
    .forEach(property => {
      if ([454, 111, 431, 430, 267, 476, 67, 40].includes(property.strapiId)) {
        // manila project ids
        properties.manila.push(property);
      } else if ([670, 687, 737, 747].includes(property.strapiId)) {
        // vismin project ids
        properties.visMin.push(property);
      } else {
        properties.mindanao.push(property); // assuming mindanao project ids:
      }
    });

  return (
    <Wrapper>
      <ProjectPropertiesComponent
        title={
          <h2>
            Our <span>Luzon</span> highlights
          </h2>
        }
        // description={
        //   <p>
        //     Within the metro or outside,
        //   </p>
        // }
        properties={properties.manila}
        padding="50px 0px 0px"
      />
      <ProjectPropertiesComponent
        title={
          <h2>
            Our <span>Visayas</span> highlights
          </h2>
        }
        // description={
        //   <p>
        //     Within the metro or outside,
        //   </p>
        // }
        properties={properties.visMin}
        padding="50px 0px 0px"
      />
      <ProjectPropertiesComponent
        title={
          <h2>
            Our <span>Mindanao</span> highlights
          </h2>
        }
        // description={
        //   <p>
        //     Within the metro or outside,
        //   </p>
        // }
        properties={properties.mindanao}
        padding="50px 0px 0px"
      />
    </Wrapper>
  );
}

export default DeveloperLandlordProperties;
