import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import ITBPOSubset from './subset/ITBPOSubset';
import GovtSubset from './subset/GovtSubset';

import AUBLogo from '../../../static/images/services/retail-clients/banks/aub.jpg';
import BDOLogo from '../../../static/images/services/retail-clients/banks/bdo.jpg';
import BPILogo from '../../../static/images/services/retail-clients/banks/bpi.jpg';
import CBLogo from '../../../static/images/services/retail-clients/banks/chinabank.jpg';

import CleanFuelLogo from '../../../static/images/services/retail-clients/gas-station/cleanfuel.jpg';
import PetronLogo from '../../../static/images/services/retail-clients/gas-station/petron.jpg';
import PhoenixLogo from '../../../static/images/services/retail-clients/gas-station/phoenix.jpg';
import UnioilLogo from '../../../static/images/services/retail-clients/gas-station/unioil.jpg';

import StarbucksLogo from '../../../static/images/services/retail-clients/starbucks.jpg';
import TimHortonsLogo from '../../../static/images/services/retail-clients/tim-hortons.jpg';
import DDLogo from '../../../static/images/services/retail-clients/dd.jpg';
import McdoLogo from '../../../static/images/services/retail-clients/mcdonalds.jpg';
import KFCLogo from '../../../static/images/services/retail-clients/kfc.jpg';
import LalamoveLogo from '../../../static/images/services/retail-clients/lalamove.jpg';
import AceLogo from '../../../static/images/services/retail-clients/ace.jpg';
import WatsonsLogo from '../../../static/images/services/retail-clients/watsons.jpg';
import ContisLogo from '../../../static/images/services/retail-clients/contis.jpg';
import SevenElevenLogo from '../../../static/images/services/retail-clients/711.jpg';
import MinistopLogo from '../../../static/images/services/retail-clients/ministop.jpg';
import AlfamartLogo from '../../../static/images/services/retail-clients/alfamart.jpg';
import CafeAmazonLogo from '../../../static/images/services/retail-clients/cafe-amazon.jpg';
import UNTVLogo from '../../../static/images/services/retail-clients/untv.jpg';
import AnytimeFitnessLogo from '../../../static/images/services/retail-clients/AF.jpg';

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  h2 {
    margin-bottom: 24px;
    span {
      font-family: Poppins, sans-serif;
      color: ${props => `${props.theme.primeBluePalette.original}`};
    }
  }
  .client-logo {
    margin-bottom: 0px;
  }
  .govt {
    padding: 40px;
  }
  .first-row {
    /* make sure this is also equal to gutter value at row */
    margin-bottom: 40px;
  }
`;
function TenantRepresentationLogos() {
  return (
    <Wrapper>
      <h2>
        <span>IT-BPO</span> clients
      </h2>
      {/* <Row className="first-row" type="flex" jus
      tify="center" gutter={[40, 40]}>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="IBEX" src={IbexLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Dynata SSI" src={DynataSSILogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="IBM" src={IBMLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Hikvision" src={HikvisionLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Inform" src={InformLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="CIIT" src={CIITLogo} />
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Demand Science" src={DSLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="SIBS Solutions" src={SibsLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="EMAPTA" src={EmaptaLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Lyanthe" src={LyantheLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Eureka" src={EurekaLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="FocusInc" src={FocusLogo} />
        </Col>
      </Row> */}
      <ITBPOSubset />
      <h2>
        <span>Government agency</span> clients
      </h2>
      <GovtSubset />
      <h2>
        <span>Banking</span> clients
      </h2>
      <Row className="first-row" type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Asia United Bank" src={AUBLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Banco de Oro" src={BDOLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img
            className="client-logo"
            alt="Bank of the Philippine Islands"
            src={BPILogo}
          />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Chinabank" src={CBLogo} />
        </Col>
      </Row>
      <h2>
        <span>Gas & Petroleum</span> clients
      </h2>
      <Row className="first-row" type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="CleanFuel" src={CleanFuelLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Petron" src={PetronLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Phoenix" src={PhoenixLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Unioil" src={UnioilLogo} />
        </Col>
      </Row>
      <h2>
        <span>Retail</span> clients
      </h2>
      <Row className="first-row" type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Starbucks" src={StarbucksLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Tim Hortons" src={TimHortonsLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Dean and Deluca" src={DDLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Mcdonalds" src={McdoLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="KFC" src={KFCLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Lalamove" src={LalamoveLogo} />
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Ace Hardware" src={AceLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Watsons" src={WatsonsLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Conti's" src={ContisLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="7-11" src={SevenElevenLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Ministop" src={MinistopLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Alfamart" src={AlfamartLogo} />
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[40, 40]}>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="Cafe Amazon" src={CafeAmazonLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img className="client-logo" alt="UNTV" src={UNTVLogo} />
        </Col>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} xxl={4}>
          <img
            className="client-logo"
            alt="Anytime Fitness"
            src={AnytimeFitnessLogo}
          />
        </Col>
      </Row>
    </Wrapper>
  );
}

export default TenantRepresentationLogos;
