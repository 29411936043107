import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  h3 {
    margin-bottom: 24px;
    line-height: 1.15;
    color: ${props => props.theme.primeBluePalette.original};
  }
  span, p, li {
    font-size: 20px;
    line-height: 28px;
  }
  strong {
    display: block;
    font-size: 24px;
    line-height: 32px;
    /* color: ${props => props.theme.primeBluePalette.original}; */
    color: ${props => props.theme.primeBluePalette.original};
  }
  ol {
    margin-top: 24px;
    margin-bottom: 0 !important;
  }
  img {
    width: 100%;
    max-width: 400px;
  }
  @media only screen and (max-width: 776px) {
    li,
    span {
      font-size: 16px;
      line-height: 22px;
    }
    
    strong {
      font-size: 20px;
      line-height: 28px;
      /* color: ${props => props.theme.primeBluePalette.original}; */
    }
  }
`;

function HLCaseStudies() {
  return (
    <Wrapper>
      <h3>Case studies</h3>
      <ul>
        <li>
          <strong>Park Hyatt Siem Reap</strong>Formerly, Hotel de la Paix Siem
          Reap, the property closed in July 2012 for an extension renovation,
          and reopened as Park Hyatt Siem Reap, in August 2013. The property
          needed a complete remodeling of all guest rooms and upgrade of public
          areas, as well as guidance during the reopening stages. <br /> Our
          consultant oversaw the closure and renovation of the hotel and then
          managed the hotel asset upon re-opening. Since re-opening the property
          has been recognized globally through several awards.
        </li>
        <li>
          <strong>Monte-Carlo Saigon, Vietnam</strong>Located inside Park Hyatt
          Saigon, the Monte-Carlo Saigon is an e-gaming facility which opened in
          February, 2015. The client required the opening of the casino to be
          completed in 45 days, including recruitment and training of staff,
          procurement of operating equipment and supplies, placement of machines
          and co-ordination of contractors. The Monte-Carlo opened on time and
          under budget through the efforts of our consultant.
        </li>
        <li>
          <strong>Grand Hyatt Manila</strong>Called in to assist the ownership
          of the hotel in completing the fit out of the hotel and have all
          facilities operational, our consultant worked with the various
          contractors and construction managers to coordinate work, and complete
          all guest rooms and F&B facilities.
        </li>
      </ul>
    </Wrapper>
  );
}

export default HLCaseStudies;
