import React from 'react';
import styled from 'styled-components';
// import { Row, Col } from 'antd';

// import E2EGraphicLarge from '../../../static/images/services/research/e2e-infographic.jpg';
import DeLunaProperty from '../../../static/images/services/research/de-luna.jpg';
import FrontrowProperty from '../../../static/images/services/research/frontrow.jpg';
import HopewellProperty from '../../../static/images/services/research/hopewell.jpg';

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  h3 {
    margin-bottom: 24px;
    line-height: 1.15;
    color: ${props => props.theme.primeBluePalette.original};
  }
  span, p, li {
    font-size: 20px;
    line-height: 28px;
  }
  strong {
    display: block;
    font-size: 24px;
    line-height: 32px;
    /* color: ${props => props.theme.primeBluePalette.original}; */
    color: ${props => props.theme.primeBluePalette.original};
  }
  ol {
    margin-top: 24px;
    margin-bottom: 0 !important;
  }
  img {
    width: 100%;
    max-width: 400px;
  }
  @media only screen and (max-width: 776px) {
    li,
    span {
      font-size: 16px;
      line-height: 22px;
    }
    
    strong {
      font-size: 20px;
      line-height: 28px;
      /* color: ${props => props.theme.primeBluePalette.original}; */
    }
  }
`;

function E2EGraphicRowComponent() {
  return (
    <Wrapper>
      <h3>Case studies</h3>
      <ol>
        <li>
          <strong>Foreign development consulting</strong>
          Foreign companies are in need of understanding the current market,
          trends, and practices of real estate in the Philippines. As such, the
          research group has provided multiple market studies and industry
          reports in order to ease the entry of chinese developers in recent
          years. Aside from real estate data, guidance on key laws and policies
          were provided, as well as insight on which property sectors are
          currently ideal for the various cities in Metro Manila.
        </li>
        <li>
          <strong>Best use advisory</strong>
          <img
            className="case-study-images"
            alt="Hotel property perspective"
            src={DeLunaProperty}
          />
          <br />
          Initially planning to put up a hotel, the construction firm requested
          for a Highest and Best Use Study in order to validate its decision.
          After receiving the assessment of the market and considering the
          various market and financial factors provided, the firm has decided to
          shift its development into an office building in order to cater to the
          underserved market in its locale.
        </li>
        <li>
          <strong>Project validation</strong>
          <img
            className="case-study-images"
            alt="Mixed-use office and hotel property perspective"
            src={FrontrowProperty}
          />
          <br />
          After facilitating the acquisition of a property in Quezon City, the
          beauty product distribution company requested for the aid of PRIME
          Philippines in conceptualizing and eventually marketing. Initially
          considering a mixed-use office and hotel development with retail
          component, the feasibility study directed the brand to focus on office
          instead. An iconic office building is soon to rise in Quezon City, as
          the brand is finalizing its design process in collaboration with PRIME
          Philippines and Vision Arch.
        </li>
        <li>
          <strong>Development viability assessment</strong>
          <img
            className="case-study-images"
            alt="Undeveloped property"
            src={HopewellProperty}
          />
          <br />
          After purchasing over a hectare of land currently for industrial use,
          the property owner was keen on developing a subdivision on it. PRIME
          Philippines was tasked to serve as market and financial consultant for
          the property. While the market dictates high viability for
          subdivision, PRIME’s recommendation was to not develop a subdivision,
          as the financial forecasts would not meet the owner’s target returns
          due to the land acquisition cost. Not all development concepts will be
          feasible, and PRIME Philippines’ unbiased assessment helped avoid
          potential loss by its client.
        </li>
      </ol>
    </Wrapper>
  );
}

export default E2EGraphicRowComponent;
